import withAuth from 'components/common/with-auth';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Header from 'components/common/header';
import Title from 'lib/Title';
import theme from 'config/theme';
import userContainer from 'containers/user';
import { Subscribe } from 'unstated';
import Main from 'components/common/main';
import routes from 'config/routes';
import api from 'api';
import { ReactComponent as ProfileIcon } from 'assets/images/digs-listing/person.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/icons/notification.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { Link } from 'react-router-dom';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors, boxShadow } = theme;

interface CardData {
	icon: JSX.Element;
	title: string;
	description: string;
	link: string;
	landlordOnly?: boolean;
}

const cards: CardData[] = [
	{
		icon: <ProfileIcon />,
		title: 'Personal information',
		description: 'Provide peronal details needed to reach you',
		link: routes.account_profile,
	},
	{
		icon: <NotificationIcon />,
		title: 'Notifications',
		description: 'Choose notification preferences on how you want to be contacted',
		link: routes.account_notifications,
	},
	{
		icon: <PhoneIcon />,
		title: 'Contact DigsConnect',
		description: 'Get in contact with our support team',
		link: routes.contact_us,
	},
	{
		icon: <HomeIcon style={{ width: 24, height: 24 }} />,
		title: 'Manage listings',
		description: 'Edit, activate and deactivate your DigsConnect listings',
		link: routes.manage_listing,
		landlordOnly: true,
	},
	{
		icon: <DocumentIcon style={{ width: 16 }} />,
		title: 'Invoices',
		description: 'View and query your DigsConnect invoices',
		link: routes.manage_listing_invoices,
		landlordOnly: true,
	},
];

const Account = () => {
	const [isLandlord, setIsLandlord] = useState(false);

	useEffect(() => {
		api.property
			.getProperties()
			.then(res => res.data)
			.then(listings => {
				setIsLandlord(!!listings && !!listings.length);
			});
	}, []);

	return (
		<>
			<Header />
			<Main>
				<ScreenContainer>
					<ScreenContent>
						<Title standardMargin>Account</Title>
						<Text>
							<Subscribe to={[userContainer]}>
								{user => <>{user.state.v2.email?.email}</>}
							</Subscribe>
						</Text>
						<CardsContainer>
							{cards
								.filter(({ landlordOnly }) => isLandlord || !landlordOnly)
								.map(card => (
									<Card to={card.link} key={card.title}>
										<IconConainer>{card.icon}</IconConainer>
										<SubTitle>{card.title}</SubTitle>
										<Text>{card.description}</Text>
									</Card>
								))}
						</CardsContainer>
					</ScreenContent>
				</ScreenContainer>
			</Main>
		</>
	);
};

const ScreenContainer = styled.div`
	border-top: 2px solid ${colors.grey10};
	width: 100%;
	padding: 24px;
`;

const ScreenContent = styled.div`
	margin: auto;
	margin-top: 24px;
	max-width: 1000px;
`;

const SubTitle = styled.div`
	font-size: 20px;
	color: ${colors.darkBlue};
	font-weight: 600;
	margin-bottom: 16px;
`;

const Text = styled.p`
	font-size: 14px;
	color: ${colors.grey60};
	margin: 0;
`;

const Card = styled(Link)`
	display: block;
	border-radius: 16px;
	box-shadow: ${boxShadow.default};
	border: 1px solid ${colors.grey10};
	width: calc(100% / 3 - 16px);
	min-height: 200px;
	padding: 24px;
	background: ${colors.white};
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		width: 100%;
		min-height: unset;
	}
`;

const IconConainer = styled.div`
	margin-bottom: 16px;
`;

const CardsContainer = styled.div`
	margin-top: 24px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	@media (max-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

export default withAuth(Account);
